/* Global rule to apply border-box to all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* New Design - LoginScreen.css */

.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000000;
  font-family: 'Poppins', sans-serif;
}

.login-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #000000;
}

.login-container img {
  width: 100px;
  margin-bottom: 20px;
}

.login-container .form-group {
  margin-bottom: 20px;
  text-align: left;
}

.login-container .form-group label {
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
  text-align: left;
}

.login-container .form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: left; /* Ensure text is aligned left */
}

.login-container .form-group input::placeholder {
  text-align: left; /* Align placeholder text to the left */
  color: #999; /* Optional: Slightly adjust placeholder color for readability */
}

.login-container .form-group input:focus {
  border-color: #007bb5;
  box-shadow: 0 0 8px rgba(0, 123, 181, 0.3);
}

.login-container button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: #000000;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.login-container button:hover {
  background-color: #005f8d;
}

.google-login-button {
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-top: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-login-button:hover {
  background-color: #f7f7f7;
}

.google-login-button svg {
  margin-right: 10px;
  font-size: 1.5rem;
}

.additional-links {
  margin-top: 30px;
}

.additional-links a {
  color: #007bb5;
  text-decoration: none;
}

.additional-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .login-container {
    width: 90%;
  }
}

/* Footer styles */
.cortex-footer {
  width: 100%; /* Ensures full-width alignment */
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ddd;
  position: relative;
  bottom: 0;
  clear: both; /* Ensures no floating elements affect it */
  box-sizing: border-box; /* Includes padding/border in width */
}

.cortex-footer-link {
  display: block;
  margin-bottom: 10px;
  color: #0056b3;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 12px;
}

.cortex-footer-link:hover {
  text-decoration: underline;
  color: #003366;
}

.cortex-footer p {
  font-size: 14px;
  margin: 0;
}
