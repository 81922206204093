/* ForgotPasswordScreen.css */

.forgot-password-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000000;
  font-family: 'Poppins', sans-serif;
}

.forgot-password-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.forgot-password-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #000000;
}

.forgot-password-container .form-group {
  margin-bottom: 20px;
  text-align: left;
}

.forgot-password-container .form-group label {
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
  text-align: left;
}

.forgot-password-container .form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  text-align: left; /* Align text to the left */
}

.forgot-password-container .form-group input::placeholder {
  text-align: left; /* Align placeholder text to the left */
  color: #999; /* Optional: Adjust placeholder color for readability */
}

.forgot-password-container .form-group input:focus {
  border-color: #007bb5;
  box-shadow: 0 0 8px rgba(0, 123, 181, 0.3);
}

.forgot-password-container button {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: #000000;
  color: #fff;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.forgot-password-container button:hover {
  background-color: #005f8d;
}

.forgot-password-container .error-message {
  color: red;
  margin-top: 10px;
}

.forgot-password-container .success-message {
  color: green;
  margin-top: 10px;
}

.forgot-password-container p {
  margin-top: 20px;
  font-size: 1.1rem;
}

.forgot-password-container p a {
  color: #007bb5;
  text-decoration: none;
}

.forgot-password-container p a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .forgot-password-container {
    width: 90%;
  }
}
