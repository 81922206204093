/* Main container */
.cortex-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

/* Form styling */
.cortex-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.cortex-form-label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #444;
}

.cortex-input {
  padding: 8px;
  border-radius: 6px;
  border: 1px solid #ccc;
  margin-top: 5px;
  transition: border-color 0.3s ease;
}

.cortex-input:focus {
  border-color: #4CAF50;
  outline: none;
}

/* Button alignment */
.button-group {
  display: flex;
  justify-content: flex-start; /* Left-align the buttons */
  gap: 15px;
  margin-top: 20px;
  grid-column: span 2; /* Ensures the buttons span across the full width of the form */
}

/* Button styling */
.cortex-button {
  padding: 10px 20px;
  border: none;
  background-color: #000000;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.cortex-button:hover {
  background-color: #0d226a;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.cortex-button:disabled {
  background-color: #cccccc;  /* Gray out the button */
  color: #666666;  /* Change text color to indicate disabled state */
  cursor: not-allowed;  /* Show not-allowed cursor */
  box-shadow: none;  /* Remove shadow to indicate it's not active */
}

/* Gallery layout */
.cortex-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
}

.cortex-image-wrapper {
  position: relative;
  width: 100%; /* Matches container width */
  padding-top: 100%; /* Maintain a square aspect ratio */
  overflow: hidden; /* Ensure no content overflows */
}

.cortex-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* Preserve aspect ratio while fitting inside */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Shadow effect */
  transition: transform 0.3s ease;
}

.cortex-image:hover {
  transform: scale(1.02);
}

/* Bounding box styling */
.bounding-box {
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
}

.bounding-label {
  position: absolute;
  left: 0;
  pointer-events: none;
  white-space: nowrap;
}


/* Loading Spinner */
.cortex-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Pagination */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.pagination-info {
  font-size: 1.2em;
  color: #444;
}

.cortex-image-container {
  margin-bottom: 20px;
  width: 300px; /* Fixed container width */
  height: 300px; /* Fixed container height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  padding: 10px; /* Inner padding */
  border-width: 1px;
  border-style: solid;
  border-color: #000000;
  box-sizing: border-box; /* Includes padding in container size */
  overflow: hidden; /* Prevent any overflow */
}


.label-quality {
  font-size: 14px;
  color: #555;
  margin-top: 10px; /* Space between the image and the label */
  text-align: center; /* Center the label text */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  h1 {
    font-size: 1.5em;
  }

  .cortex-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  .cortex-form {
    grid-template-columns: 1fr;
  }

  .pagination-info {
    font-size: 1em;
  }
}

/* Center message styling */
.center-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  font-size: 1.5em;
  color: #555;
  text-align: center;
  margin: 0;
}

/* Apply to html and body to make them full height */
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

/* Page container to handle the entire layout */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes up the full height of the viewport */
  overflow: hidden; /* Prevent unnecessary scrollbars */
}

/* Content wrap to fill available space */
.content-wrap {
  flex: 1; /* This makes the content stretch to fill the space between the header and footer */
}

/* Center the no images found message */
.no-images-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  color: #555;
  text-align: center;
  height: 100%;
}

/* Align the user info, title, and logout button */
.cortex-user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cortex-username {
  font-size: 1.2em;
  color: #333;
}

h1 {
  flex-grow: 1;
  text-align: center;
  font-size: 2em;
  color: #333;
}

/* Logout button styling */
.logout-button {
  padding: 10px 20px;
  border: none;
  background-color: #e74c3c;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-left: 10px; /* Add space above the logout button */
}

.logout-button:hover {
  background-color: #c0392b;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

/* Cortex.css */

.stripe-portal-button {
  padding: 10px 20px;
  border: none;
  background-color: #6772e5;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.stripe-portal-button:hover {
  background-color: #5469d4; /* Darker shade on hover */
}

.stripe-portal-button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(103, 114, 229, 0.5); /* Light shadow with Stripe color */
}

/* Ensure the main container stretches to fill available space */
.cortex-container {
  flex: 1; /* Allow this container to grow and fill the space */
  display: flex;
  flex-direction: column;
}

/* Ensure the main container grows to fill available space */
.cortex-container {
  flex: 1; /* Allow the container to grow and take up available vertical space */
  display: flex;
  flex-direction: column;
}

/* Footer styles */
.cortex-footer {
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ddd; /* Optional: Top border */
  position: relative;
  bottom: 0;
  width: 100%;
}

.cortex-footer-link {
  display: block;
  margin-bottom: 10px;
  color: #007bff; /* Optional: Link color */
  text-decoration: none;
}

.cortex-footer-link:hover {
  text-decoration: underline;
}

.cortex-footer p {
  font-size: 14px;
  margin: 0;
}
